<template>
  <div class="container">
    <div class="content">
      <h1 style="font-size:40px;">{{ from.userName }}</h1>
      <div style="margin-top: 20px; font-size:20px;line-height:20px;">
        <i class="el-icon-user-solid"></i>
        {{ from.email }}
      </div>
      <el-divider></el-divider>
      <div style="width: 100%; display: flex;">
        <div style="width: 50%;">
          <div style="display: flex;"> 
            <div style="width: 130px; text-align: right;">用户名</div>
            <div style="margin-left: 40px;font-weight: bold; color: #666;">
              <el-input v-model="from.userName" v-if="isEdit" style="margin-top: -20px;" disabled></el-input>
              <span v-else>{{ from.userName }}</span>
            </div>.
          </div>
          <div style="display: flex; margin-top: 20px;"> 
            <div style="width: 130px; text-align: right;">生日</div>
            <div style="margin-left: 40px;font-weight: bold; color: #666;">
              <el-input v-model="from.brithday" v-if="isEdit" style="margin-top: -20px;"></el-input>
              <span v-else>{{ from.brithday }}</span>
            </div>
          </div>
          <div style="display: flex; margin-top: 20px;"> 
            <div style="width: 130px; text-align: right;">用户类型</div>
            <div style="margin-left: 40px;font-weight: bold; color: #666;">
              <el-select v-model="from.type" v-if="isEdit" style="margin-top: -20px;" placeholder="请选择" disabled>
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
              <span v-else>{{ from.type === 1 ? '个人用户' : from.type === 2 ? '校园用户' : from.type === 3 ? '政府或机构' : '管理员' }}</span>
            </div>
          </div>
          <div style="display: flex; margin-top: 20px;"> 
            <div style="width: 130px; text-align: right;">公司</div>
            <div style="margin-left: 40px;font-weight: bold; color: #666;">
              <el-input v-model="from.org" v-if="isEdit" style="margin-top: -20px;"></el-input>
              <span v-else>{{ from.org }}</span>
            </div>
          </div>
          <div style="margin-top: 40px;">
            <el-divider></el-divider>
          </div>
          <div style="display: flex; margin-top: 20px;"> 
            <div style="width: 130px; text-align: right;">省</div>
            <div style="margin-left: 40px;font-weight: bold; color: #666;">
              <el-select v-model="from.sheng" v-if="isEdit" style="margin-top: -20px;" placeholder="请选择" @change="provinceChange">
                <el-option
                  v-for="item in province"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span v-else>{{ getShengName(from.sheng) }}</span>
            </div>
          </div>

          <div style="display: flex; margin-top: 20px;"> 
            <div style="width: 130px; text-align: right;">市</div>
            <div style="margin-left: 40px;font-weight: bold; color: #666;">
              <el-select v-model="from.shi" v-if="isEdit" style="margin-top: -20px;" placeholder="请选择" @change="cityChange">
                <el-option
                  v-for="item in cityOption"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span v-else>{{ getShiName(from.shi) }}</span>
            </div>
          </div>

          <div style="display: flex; margin-top: 20px;"> 
            <div style="width: 130px; text-align: right;">区/县</div>
            <div style="margin-left: 40px;font-weight: bold; color: #666;">
              <el-select v-model="from.xian" v-if="isEdit" style="margin-top: -20px;" placeholder="请选择">
                <el-option
                  v-for="item in countyOption"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
              <span v-else>{{ getXianName(from.xian) }}</span>
            </div>
          </div>
         
          
          <div style="margin-top: 40px;">
            <el-divider></el-divider>
          </div>
          <div style="display: flex; margin-top: 20px;"> 
            <div style="width: 130px; text-align: right;">邮箱</div>
            <div style="margin-left: 40px;font-weight: bold; color: #666;">
              <el-input v-model="from.email" v-if="isEdit" style="margin-top: -20px;"></el-input>
              <span v-else>{{ from.email }}</span>
            </div>
          </div>
          <div style="display: flex; margin-top: 20px;"> 
            <div style="width: 130px; text-align: right;">电话</div>
            <div style="margin-left: 40px;font-weight: bold; color: #666;">
              <el-input v-model="from.phone" v-if="isEdit" style="margin-top: -20px;"></el-input>
              <span v-else>{{ from.phone }}</span>
            </div>
          </div>
        </div>

        <div class="avatar">
          <img src="@/assets/user.png">
        </div>
      </div>

      <el-divider></el-divider>
    </div>
    <div style="text-align: center;">
        <el-button style="width: 200px;" v-if="!isEdit" @click="edit">修改</el-button>
        <el-button style="width: 200px;" type="primary" v-else @click="submit">保存</el-button>
    </div>
  </div>
</template>

<script>
import $cookies from 'vue-cookies'
import { $user } from "@/services";
import province from './data/province.json'
import county from './data/county.json'
import city from './data/city.json'
export default {
  data () {
    return {
        province,city,county,
        isEdit:false,
        from:{},
        options:[
          {
            value:1,
            label: '个人用户'
          },
          {
            value:2,
            label: '校园用户'
          },
          {
            value:3,
            label: '政府或机构'
          }
        ],
        cityOption:[],
        countyOption:[]
    }
  },
  created () {
    this.from = $cookies.get('userInfo')
  },
  methods: {
    async submit() {
      let res = await $user.updateUser2(this.from)
      if (res.code === 0) {
        this.$message.success('修改成功')
        $cookies.set('userInfo', this.from, '30MIN')
        this.isEdit = false
      }
    },
    provinceChange(e) {
      this.cityOption = this.city[e]
      this.from.shi = ''
      this.from.xian = ''
    },
    cityChange(e) {
      this.countyOption = this.county[e]
      this.from.xian = ''
    },
    getShengName(id) {
      if (!id) return
      let obj = this.province.find(item => item.id === id)
      console.log(obj,11)
      if (obj) {
        return obj.name
      }
    },
    getShiName(id) {
      if (!id) return
      let obj = this.city[this.from.sheng].find(item => item.id === id)
      if (obj) {
        return obj.name
      } 
    },
    getXianName(id) {
      if (!id) return
      let obj = this.county[this.from.shi].find(item => item.id === id)
      if (obj) {
        return obj.name
      }
    },
    edit() {
      this.isEdit = true
      this.cityOption = this.city[this.from.sheng]
      this.countyOption = this.county[this.from.shi]
    }
  },
}
</script>

<style lang="less" scoped>
.container {
  background: #fff;
  .content {
    width: 1420px;
    margin: 20px auto;
    color: #666;
    font-weight: 350;
  }
}
.avatar {
  width: 50%;
  text-align: center;
  >img {
    width: 460px;height: 460px;
    border-radius: 50%;
  }
    
}
</style>